.tax-organizer-wrapper{font-family:"Roboto","arial","san-serif";font-size:14px}.tax-organizer-wrapper .page__content--with-tabs .tip-parent .tip-container{top:-132px;right:45px}.tax-organizer-wrapper .page__content--with-tabs .tip-parent .tip-container.bottom{top:-137px;left:25px}.tax-organizer-wrapper .tip-parent{position:relative;max-width:1010px}.tax-organizer-wrapper .tip-parent .tip-container{position:absolute;display:flex;top:34px;right:60px}.tax-organizer-wrapper .tip-parent .tip-container.bottom{top:-150px;left:25px}.tax-organizer-wrapper .tip-parent .tip-container.bottom .tip-icon{left:0px;top:-3px}.tax-organizer-wrapper .tip-parent .tip-container.bottom .tip-bg{margin-left:30px}.tax-organizer-wrapper .tip-parent .tip-container .tip-icon{position:absolute;z-index:0;top:-13px;right:-10px}.tax-organizer-wrapper .tip-parent .tip-container .tip-bg{display:flex;z-index:1;font-size:14px;margin-right:12px}.tax-organizer-wrapper .tip-parent .tip-container .tip-bg .tip-title{font-weight:bold}.tax-organizer-wrapper .tip-parent .tip-container .tip-bg .tip-link{cursor:pointer;text-decoration:underline;color:#0065ff}.tax-organizer-wrapper .ontask-container__title{padding-left:32px;padding-top:32px;font-family:Roboto;font-style:normal;font-weight:bold;font-size:16px;line-height:22px;color:#212121;letter-spacing:0.25px}.tax-organizer-wrapper .ontask-container iframe{padding:0 32px;padding-left:32px}.tax-organizer-wrapper .full-form-width{margin-left:-32px;margin-right:calc(-100vw / 2 + 956px / 2)}.tax-organizer-wrapper__title{height:48px;display:flex;align-items:center;padding-left:16px;border-bottom:1px solid #edebe9}.tax-organizer-wrapper__title span{font-family:Roboto;font-style:normal;font-weight:700;font-size:16px;line-height:22px;color:#212121;letter-spacing:0.25px}.tax-organizer-wrapper .error-alert{padding:30px;color:#a80000}

.tax-organizer-wrapper .page{max-width:calc(100% - 100px);min-width:960px;padding:0 50px}.tax-organizer-wrapper .page__header{padding:24px 0;display:flex;justify-content:space-between;align-items:center}.tax-organizer-wrapper .page__header--with-tabs{border-bottom:1px solid #edebe9}.tax-organizer-wrapper .page__title{font-size:1rem;font-weight:bold;margin:0;padding:0}.tax-organizer-wrapper .page__content{margin-top:1rem}.tax-organizer-wrapper .page__content--with-tabs{margin-top:0}.tax-organizer-wrapper .page__footer{margin-top:2rem;padding-top:2rem;position:relative}.tax-organizer-wrapper .page__footer:before{content:'';border-top:1px solid #edebe9;position:absolute;top:0;margin-left:-50px;width:calc(100% + 100px)}.tax-organizer-wrapper .page .action-buttons{display:flex;justify-content:space-between}

.tax-organizer-wrapper .loading{margin:5rem 0;display:flex;flex-direction:column;justify-content:center;align-items:center}.tax-organizer-wrapper .loading__text{margin-top:1rem;text-transform:capitalize}

.tax-organizer-wrapper .list-form__list{background-color:#fdfcfc;border:1px solid #d9d9d9;border-radius:0.5rem}.tax-organizer-wrapper .list-form__list__empty-box{display:flex;align-items:center;background-color:#fdfcfc;border:1px solid #d9d9d9;padding:78px 24px 78px 24px}.tax-organizer-wrapper .list-form__list__empty-msg strong{font-weight:bold}.tax-organizer-wrapper .list-form__item{display:flex;justify-content:space-between;padding:12px 18px;border-bottom:1px solid #d9d9d9}.tax-organizer-wrapper .list-form__item:last-child{border-bottom:none}.tax-organizer-wrapper .list-form__item__name{display:flex;align-items:center}.tax-organizer-wrapper .list-form__item__icon{max-width:1rem;max-height:1rem;margin-right:0.5rem}

.tax-organizer-wrapper .container{display:flex;flex-direction:column;max-width:960px;margin:12px}.tax-organizer-wrapper .container .loading{display:flex;justify-content:center}.tax-organizer-wrapper .container .tabs{margin:0 -50px;padding:0 50px}.tax-organizer-wrapper .container .tabs *{text-transform:none !important}.tax-organizer-wrapper .container .tabs .tab{padding:0 2rem}.tax-organizer-wrapper .container .tabs .tab.invalid svg{color:#fc0}.tax-organizer-wrapper .container .footer{padding-left:32px;border-top:1px solid #edebe9;max-width:960px}.tax-organizer-wrapper .container .action-buttons{margin:32px 0;display:flex;justify-content:space-between}

.tax-organizer-wrapper .loading-iframe{height:1px !important;visibility:hidden}

.tax-organizer-wrapper .welcome-container{background-color:#fff;display:flex;flex-direction:column}.tax-organizer-wrapper .welcome-container__message{display:flex;justify-content:center;padding-top:2.5rem}.tax-organizer-wrapper .welcome-container__message span{font-family:Roboto;font-style:normal;font-weight:bold;font-size:24px;line-height:32px;color:#212121;text-align:center}.tax-organizer-wrapper .welcome-container__start{display:flex;justify-content:center;margin-top:2.5rem}.tax-organizer-wrapper .welcome-container__start button{background:#0065ff;border-radius:2px;padding:7px 16px}.tax-organizer-wrapper .welcome-container__start button,.tax-organizer-wrapper .welcome-container__start button span{font-family:Roboto;font-style:normal;font-weight:500;color:#ffffff;font-size:14px;line-height:20px}.tax-organizer-wrapper .welcome-container__background{display:flex;justify-content:center;background-color:#fff}.tax-organizer-wrapper .welcome-container__background svg{width:unset;height:unset;fill:none}

.tax-organizer-wrapper .taxdocuments{background-color:#fff}.tax-organizer-wrapper .taxdocuments .MuiTabs-root{padding-left:32px}.tax-organizer-wrapper .taxdocuments__footer{margin-top:32px;padding-left:32px;padding-bottom:160px;border-top:1px solid #edebe9;max-width:960px}.tax-organizer-wrapper .taxdocuments__buttons{margin:32px 0;display:flex;justify-content:space-between}

.tax-organizer-wrapper .uploader-zone .e-css.e-btn{box-shadow:none;background-color:#ffffff;border:1px solid #8a8886}.tax-organizer-wrapper .uploader-zone .e-upload .e-upload-files .e-upload-file-list .e-file-container .e-progress-inner-wrap .e-upload-progress-bar.e-upload-progress{background:#0065ff;height:8px;border-radius:4px}.tax-organizer-wrapper .uploader-zone .e-upload .e-upload-files .e-upload-file-list .e-file-container .e-progress-inner-wrap{background-color:#b0b0b0;height:8px;border-radius:4px}.tax-organizer-wrapper .uploader-zone .e-upload-file-list .e-icons{color:rgba(0,0,0,0.54);transition:all 300 ease}.tax-organizer-wrapper .uploader-zone .e-upload-file-list .e-icons:hover{color:#0065ff;background-color:#e8f2ff !important}

table tr.title{background:#e3e3e3}table tr.title td{padding:8px 14px}table tr.title td span{font-family:Roboto;font-style:normal;font-weight:700;font-size:14px;line-height:20px;color:#212121;letter-spacing:0.25px}table tr td{padding:1px 11.53px 5px 12px;border:1px solid #d9d9d9;vertical-align:middle}table tr td .Mui-checked .MuiSvgIcon-root{fill:#0065ff}table tr td .MuiSvgIcon-root{fill:rgba(0,0,0,0.54)}table tr td .MuiIconButton-root:hover,table tr td .MuiCheckbox-colorSecondary.Mui-checked:hover,table tr td .Mui-checked:hover{background-color:#e8f2ff}table tr td .MuiCheckbox-root.Mui-disabled .MuiSvgIcon-root{fill:#ccc}table tr td span{font-family:Roboto;font-style:normal;font-weight:normal;font-size:14px;line-height:20px;color:#212121;letter-spacing:0.25px}table tr td[align='center'] [role='radiogroup']{justify-content:center}

.tax-organizer-wrapper .currentyeardocuments{margin-top:24px;padding-left:32px;max-width:960px;display:flex;flex-direction:column}.tax-organizer-wrapper .currentyeardocuments__sectiontitle{font-family:Roboto;font-style:normal;font-weight:700;font-size:16px;line-height:22px;color:#212121;letter-spacing:0.25px;margin-bottom:8px}.tax-organizer-wrapper .currentyeardocuments .e-upload{margin-bottom:8px}.tax-organizer-wrapper .currentyeardocuments .e-upload .e-file-select-wrap{padding:16px 24px}.tax-organizer-wrapper .currentyeardocuments .e-upload .e-css.e-btn{text-transform:none;padding:6px 20px;font-family:Roboto;font-style:normal;font-weight:500;font-size:14px;line-height:20px;color:#212121;letter-spacing:0.25px}.tax-organizer-wrapper .currentyeardocuments .e-upload .e-file-drop{font-family:Roboto;font-style:normal;font-weight:500;font-size:14px;line-height:20px;color:#212121;letter-spacing:0.25px;color:#4a4a4a}.tax-organizer-wrapper .currentyeardocuments p{font-family:Roboto;font-style:normal;font-weight:normal;font-size:14px;line-height:20px;color:#212121;letter-spacing:0.25px;margin-bottom:8px}.tax-organizer-wrapper .currentyeardocuments p:first-of-type{margin-bottom:24px}.tax-organizer-wrapper .currentyeardocuments p.bold,.tax-organizer-wrapper .currentyeardocuments p span.bold{font-family:Roboto;font-style:normal;font-weight:700;font-size:14px;line-height:20px;color:#212121;letter-spacing:0.25px}.tax-organizer-wrapper .currentyeardocuments p a{text-decoration:underline;color:#0065ff}

.tax-organizer-wrapper .prior-year{margin-top:24px;padding-left:32px;max-width:960px;display:flex;flex-direction:column}.tax-organizer-wrapper .prior-year__sectiontitle{font-family:Roboto;font-style:normal;font-weight:700;font-size:16px;line-height:22px;color:#212121;letter-spacing:0.25px;margin-bottom:8px}.tax-organizer-wrapper .prior-year .e-upload{margin-bottom:8px}.tax-organizer-wrapper .prior-year .e-upload .e-file-select-wrap{padding:16px 24px}.tax-organizer-wrapper .prior-year .e-upload .e-css.e-btn{text-transform:none;padding:6px 20px;font-family:Roboto;font-style:normal;font-weight:500;font-size:14px;line-height:20px;color:#212121;letter-spacing:0.25px}.tax-organizer-wrapper .prior-year .e-upload .e-file-drop{font-family:Roboto;font-style:normal;font-weight:500;font-size:14px;line-height:20px;color:#212121;letter-spacing:0.25px;color:#4a4a4a}.tax-organizer-wrapper .prior-year p{font-family:Roboto;font-style:normal;font-weight:normal;font-size:14px;line-height:20px;color:#212121;letter-spacing:0.25px;margin-bottom:8px}.tax-organizer-wrapper .prior-year p:first-of-type{margin-bottom:24px}.tax-organizer-wrapper .prior-year p.bold,.tax-organizer-wrapper .prior-year p span.bold{font-family:Roboto;font-style:normal;font-weight:700;font-size:14px;line-height:20px;color:#212121;letter-spacing:0.25px}.tax-organizer-wrapper .prior-year p a{text-decoration:underline;color:#0065ff}.tax-organizer-wrapper .prior-year table{margin-top:16px}

.tax-organizer-wrapper .not-found{background-color:#fff;display:flex;flex-direction:column}.tax-organizer-wrapper .not-found__title{display:flex;justify-content:center;padding-top:2.5rem}.tax-organizer-wrapper .not-found__title span{font-family:Roboto;font-style:normal;font-weight:bold;font-size:24px;line-height:32px;color:#212121;text-align:center}.tax-organizer-wrapper .not-found__message{display:flex;justify-content:center;padding-top:2.5rem}.tax-organizer-wrapper .not-found__message span{font-family:Roboto;font-style:normal;font-weight:400;font-size:16px;line-height:22px;color:#212121;letter-spacing:0.25px;text-align:center}.tax-organizer-wrapper .not-found__svg{display:flex;justify-content:center;background-color:#fff;margin-top:6rem}.tax-organizer-wrapper .not-found__svg svg{width:unset;height:unset;fill:none}

.tax-organizer-wrapper .left-hand-navigation{background-color:#f2f2f2;min-height:calc(100vh - 100px);padding-top:48px;height:100%}.tax-organizer-wrapper .left-hand-navigation__list{padding:0}.tax-organizer-wrapper .left-hand-navigation__list:first-child{border-top:1px solid #bebebe;border-bottom:1px solid #bebebe}.tax-organizer-wrapper .left-hand-navigation__list--child{background-color:#fdfcfc;padding:0}.tax-organizer-wrapper .left-hand-navigation__list--child .left-hand-navigation__list--child{margin-left:10px}.tax-organizer-wrapper .left-hand-navigation__list--child .left-hand-navigation__item:hover{background-color:transparent}.tax-organizer-wrapper .left-hand-navigation__list--child .left-hand-navigation__item--has-children:hover{background-color:#e8f2ff}.tax-organizer-wrapper .left-hand-navigation__list--child .left-hand-navigation__subnav-icon{left:26px}.tax-organizer-wrapper .left-hand-navigation__item{position:relative;padding-left:1.5rem}.tax-organizer-wrapper .left-hand-navigation__item:hover,.tax-organizer-wrapper .left-hand-navigation__item--open,.tax-organizer-wrapper .left-hand-navigation__item--active{color:#0065ff;background-color:#e8f2ff}.tax-organizer-wrapper .left-hand-navigation__item:hover .left-hand-navigation__item-icon svg,.tax-organizer-wrapper .left-hand-navigation__item--open .left-hand-navigation__item-icon svg,.tax-organizer-wrapper .left-hand-navigation__item--active .left-hand-navigation__item-icon svg{fill:#0065ff}.tax-organizer-wrapper .left-hand-navigation__item-text span{font-size:14px}.tax-organizer-wrapper .left-hand-navigation__item-icon{min-width:26px}.tax-organizer-wrapper .left-hand-navigation__item-icon svg{fill:rgba(0,0,0,0.54)}.tax-organizer-wrapper .left-hand-navigation__subnav-icon{position:absolute;left:5px}.tax-organizer-wrapper .left-hand-navigation__item-status{min-width:0}

.tax-organizer-wrapper .empty-foreign{padding:36px 31px;max-width:980px}.tax-organizer-wrapper .empty-foreign__title{font-weight:bold;font-size:16px;margin-bottom:22px}.tax-organizer-wrapper .empty-foreign__message{display:flex;justify-content:center;flex-direction:column;width:924px;height:196px;background:#fdfcfc;border:1px solid #d9d9d9;border-radius:5px}.tax-organizer-wrapper .empty-foreign__message p{margin-left:24px}.tax-organizer-wrapper .empty-foreign__message p a{text-transform:uppercase;color:#0065ff;text-decoration:underline;font-weight:bold}.tax-organizer-wrapper .empty-foreign__footer{border-top:1px solid #edebe9;max-width:960px}.tax-organizer-wrapper .empty-foreign__buttons{margin:32px 0;display:flex;justify-content:space-between}

.txo-action-dropdown__item:hover{background-color:#e8f2ff !important}.txo-action-dropdown__item-text{margin:0 !important;font-weight:300;font-size:13px;line-height:18px;letter-spacing:0.5px}.txo-action-dropdown__item-text span{color:#4a4a4a;font:inherit}.txo-action-dropdown__item-text--disabled{color:#b0b0b0}

.tax-organizer-wrapper .status-page{padding:36px 31px;max-width:980px}.tax-organizer-wrapper .status-page__title{font-weight:bold;font-size:16px;margin-bottom:22px}.tax-organizer-wrapper .status-page__message{display:flex;justify-content:center;flex-direction:column;width:924px;height:196px;background:#fdfcfc;border:1px solid #d9d9d9;border-radius:5px}.tax-organizer-wrapper .status-page__ok{font-family:Roboto;font-style:normal;font-weight:normal;font-size:14px;line-height:20px;color:#212121;letter-spacing:0.25px;letter-spacing:0.25px;margin-left:24px;margin-bottom:20px}.tax-organizer-wrapper .status-page__ok .bold{font-family:Roboto;font-style:normal;font-weight:bold;font-size:14px;line-height:20px;color:#212121;letter-spacing:0.25px;margin-bottom:20px}.tax-organizer-wrapper .status-page__fail{margin-left:24px;font-family:Roboto;font-style:normal;font-weight:normal;font-size:14px;line-height:20px;color:#212121;letter-spacing:0.25px;letter-spacing:0.25px;display:flex}.tax-organizer-wrapper .status-page__fail p{margin-left:8.4px}.tax-organizer-wrapper .status-page__footer{border-top:1px solid #edebe9;max-width:960px}.tax-organizer-wrapper .status-page__buttons{margin:32px 0;display:flex;justify-content:space-between}.tax-organizer-wrapper .status-page__buttons button{min-width:unset}

